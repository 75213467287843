import { saveAs } from "file-saver"
// import XLSX from 'xlsx'
import { utils, write } from 'xlsx'

// ** Converts table to CSV
const objectToCsv = function (data) {
  const csvRows = []
  /* Get headers as every csv data format
  has header (head means column name)
  so objects key is nothing but column name
  for csv data using Object.key() function.
  We fetch key of object as column name for
  csv */
  const headers = Object.keys(data[0])

  /* Using push() method we push fetched
     data into csvRows[] array */
  csvRows.push(headers.join(','))

  // Loop to get value of each objects key
  for (const row of data) {
    const values = headers.map(header => {
      const val = row[header]
      return `"${val}"`
    })

    // To add, separator between each value
    csvRows.push(values.join(','))
  }

  /* To add new line for each objects values
     and this return statement array csvRows
     to this function.*/
  return csvRows.join('\n')
}

// ** Downloads CSV
function generateCSV(title, array) {
  const link = document.createElement('a')
  let csv = objectToCsv(array)
  if (csv === null) return

  const filename = title ? `${title}.xlsx` : 'Berita export.xlsx'
  if (!csv.match(/^data:text\/csv/i)) {
    csv = `data:text/csv;charset=utf-8,${csv}`
  }
  link.setAttribute('href', encodeURI(csv))
  link.setAttribute('download', filename)
  link.click()
}


// export const downloadCSV = (title = '', array) => generateCSV(title, array)

const testing = (title, array) => {
  /* make the worksheet */
  const ws = utils.json_to_sheet(array)

  /* add to workbook */
  const wb = utils.book_new()
  utils.book_append_sheet(wb, ws, title)
  // utils.book_append_sheet(wb, ws, "People2")

  /* write workbook (use type 'binary') */
  // XLSX is a ZIP-based format,
  //  Attempts to write a ZIP-based format as type "string" are explicitly blocked due to various issues with getting other browser APIs to play nice with the string.
  //   The "string" output format is primarily for text-based formats like HTML and CSV where you don't want to convert Chinese and other characters back to raw bytes.
  const wbout = write(wb, { bookType: "xlsx", type: "binary" })

  /* generate a download */
  function s2ab(s) {
    const buf = new ArrayBuffer(s.length)
    const view = new Uint8Array(buf)
    for (let i = 0; i !== s.length; ++i) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }
  // The preference for application/octet-stream probably has to do with IE6 compatibility.
  saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${title}.xlsx`)
}


export const downloadCSV = (title = '', array) => testing(title, array)