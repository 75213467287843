// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
// ** UseJWT import to get config
import useJwt from '@src/auth/jwt/useJwt'
const config = useJwt.jwtConfig

// ** Function

export const handleLogin = createAsyncThunk(
  'auth/handleLogin',
  async (payload, { dispatch, getState }) => {
    // const response = await axios.delete(`/api/private/banner/${id}`)
    // await dispatch(getData(getState().banner.params))
    // return response.data

    // ** Add to user, accessToken & refreshToken to localStorage
    localStorage.setItem('userData', JSON.stringify(payload))
    localStorage.setItem(
      config.storageTokenKeyName,
      JSON.stringify(payload.accessToken)
    )
    localStorage.setItem(
      config.storageRefreshTokenKeyName,
      JSON.stringify(payload.refreshToken)
    )
  }
)

export const handleLogout = createAsyncThunk(
  'auth/handleLogout',
  async (payload, { dispatch, getState }) => {
    localStorage.removeItem('userData')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    localStorage.removeItem('localRegional')
    localStorage.clear()
    window.location = '/login'

    const config = {
      headers: {
        Accept: 'application/json'
        // 'Content-Type': 'multipart/form-data'
      }
      // onUploadProgress: (progressEvent) => {
      //   const { loaded, total } = progressEvent
      //   const percent = Math.floor((loaded * 100) / total)
      //   console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      //   if (percent <= 100) {
      //     // dispatch({
      //     //   type: 'UPLOAD_PROGRESS',
      //     //   data: percent
      //     // })
      //   }
      // }
    }
    return await axios
      .put(`/api/private/auth/signout`)
      .then((res) => {
        // toast.success(res?.data.alert.message)
        // dispatch(getData(getState().booking.params))
        // localStorage.clear()
        return res
      })
      .catch((err) => {
        return err
      })
  }
)

export const _ = createSlice({
  name: 'auth',
  initialState: {
    data: [],
    selected: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selected: (state, action) => {
  //     state.selected = action.payload
  //   }
  // },
  extraReducers: (builder) => {
    builder
      .addCase(handleLogin.fulfilled, (state, action) => {
        // state.selected = action.payload || {}
      })
      .addCase(handleLogout.fulfilled, (state, action) => {
        // state.selected = action.payload || {}
      })
  }
})
export default _.reducer
