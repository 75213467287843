// ** Initial State
const initialState = {
  listProvinsi: [],
  listDaerah: [],
  listCategory: [],
  listTopic: [],
  data: [],
  roleModule: {},
  selected: {},

  total: 1,
  params: {},
  alert: {},
  alertSave: {},
  loading: false
}

const roles = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    // case 'GET_LIST_PROVINCE':
    //   return {
    //     ...state,
    //     listProvinsi: action.data,
    //     total: action.totalPages,
    //     params: action.params
    //   }
    // case 'GET_LIST_REGIONAL':
    //   return {
    //     ...state,
    //     listDaerah: action.data,
    //     total: action.totalPages,
    //     params: action.params
    //   }
    // case 'GET_LIST_CATEGORY':
    //   return {
    //     ...state,
    //     listCategory: action.listCategory,
    //     total: action.totalPages,
    //     params: action.params
    //   }
    // case 'GET_LIST_TOPIC':
    //   return {
    //     ...state,
    //     listTopic: action.listTopic,
    //     total: action.totalPages,
    //     params: action.params
    //   }
    case 'GET_LIST_ROLE':
      return { ...state, alert: action.alert, data: action.data, alertSave: {}, loading: false}
    case 'GET_DETAIL':
      return { 
        ...state, 
        selected: action.data 
      }
    case 'SELECTED':
      return { 
        ...state, 
        selected: action.data 
      }
    case 'GET_MODULE_BY_ROLE':
      return { ...state, alert: action.alert, roleModule: action.data, alertSave: {}, loading: false, params: action.params}
    default:
      return { ...state, alert: action.alert, loading: false  }
  }
}
export default roles
