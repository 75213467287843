// ** Initial State
const initialState = {
  allData: [],
  data: [],
  total: 1,
  params: {},
  selectedUser: null,
  selectedTicket: null,
  alert: {},
  alertSave: {},
  loading: false
}

const tickets = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data }
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_CATEGORY':
      return {
        ...state,
        listCategory: action.data,
        total: action.totalPages,
        params: action.params
      }
      
    case 'GET_USER':
      return { ...state, selectedUser: action.selected }
    case 'GET_TICKET':
      return { ...state, selectedTicket: action.selected }
    case 'ADD_USER':
      return { ...state, alert: action.data.alert }
    case 'CREATE_TICKET':
      return { ...state, alert: action.alert }
    case 'DELETE_USER':
      return { ...state }
    default:
      return { ...state, alert: action.alert  }
  }
}
export default tickets
