// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
// Toast
import ToastComponent from '@components/toast'
import { toast } from 'react-toastify'

// ** progress
export const progressEvent = createAsyncThunk('uploads/progressEvent', values => {
  return values
})

// ** Upload Globals
export const uploadFiles = createAsyncThunk('uploads/uploadFiles', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  const {
    prefix, bucket, files, caption
  } = payload

  const form = new FormData()
  form.append('prefix', prefix)
  form.append('bucket', bucket)
  form.append('caption', caption)

  console.log('payload', payload)
  if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
  // for (const file of files) {
  //   form.append("files", file)
  // }
  // for (key in form) {
  //   console.log(payload, key)
  // }
  const response = await axios.post(`/api/private/upload/image`, form, config)
  return response?.data || {}
})

export const uploadImages = createAsyncThunk('uploads/uploadImages', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      dispatch(progressEvent({type: 'image', percent}))
      if (percent <= 100) {

      }
    }
  }
  
  const {
    id, prefix, bucket, files, notes
  } = payload

  const form = new FormData()
  if (prefix) form.append('prefix', prefix)
  if (bucket) form.append('bucket', bucket)
  if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
  form.append('notes', notes)
  // for (key in form) {
  //   console.log(payload, key)
  // }
  return await axios.post(`/api/private/upload/image`, form, config).then(res => {
    return res.data.data
  }).catch(err => {
    console.error('err', err)
    toast.success(<ToastComponent
      code={500}
      message={`Upload Failed: ${err.message}`}
      color='danger'
    />, { autoClose: 5000, hideProgressBar: true, closeButton: true})
  })
})

export const uploadImageThumbnail = createAsyncThunk('uploads/uploadImageThumbnail', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      dispatch(progressEvent({type: 'image', percent}))
      if (percent <= 100) {

      }
    }
  }
  
  const {
    id, prefix, bucket, files, notes
  } = payload

  const form = new FormData()
  if (prefix) form.append('prefix', prefix)
  if (bucket) form.append('bucket', bucket)
  if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
  form.append('notes', notes)
  // for (key in form) {
  //   console.log(payload, key)
  // }
  return await axios.post(`/api/private/upload/image-thumbnail`, form, config).then(res => {
    return res.data.data
  }).catch(err => {
    console.error('err', err)
    toast.success(<ToastComponent
      code={500}
      message={`Upload Failed: ${err.message}`}
      color='danger'
    />, { autoClose: 5000, hideProgressBar: true, closeButton: true})
  })
})

export const uploadAudio = createAsyncThunk('uploads/uploadAudio', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      dispatch(progressEvent({type: 'audio', percent}))
      if (percent <= 100) {
      }
    }
  }
  
  const {
    id, prefix, bucket, files, notes
  } = payload

  const form = new FormData()
  if (prefix) form.append('prefix', prefix)
  if (bucket) form.append('bucket', bucket)
  if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
  form.append('notes', notes)
  const res = await axios.post(`/api/private/upload/image`, form, config)
  return res.data.data
})
export const uploadVideo = createAsyncThunk('uploads/uploadVideo', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (e) => {
      const { loaded, total } = e
      const percent = Math.floor((loaded * 100) / total)
      dispatch(progressEvent({type: 'video', percent}))
      if (percent <= 100) {
      }
    }
  }
  
  const {
    id, prefix, bucket, files, notes
  } = payload

  const form = new FormData()
  if (prefix) form.append('prefix', prefix)
  if (bucket) form.append('bucket', bucket)
  if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
  form.append('notes', notes)
  const res = await axios.post(`/api/private/upload/video`, form, config)
  return res.data.data
})

export const _ = createSlice({
  name: 'uploads',
  initialState: {
    data: [],
    selected: {},
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
    progress: 0,
    progressAudio: 0,
    progressImage: 0,
    progressVideo: 0
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selectedNews: (state, action) => {
  //     state.selectedNews = action.payload
  //   }
  // },
  extraReducers: builder => {
    builder.addCase(progressEvent.fulfilled, (state, action) => {
      console.log(action.payload)
      const {type, percent} = action.payload
      if (type === 'audio') {
        state.progressAudio = percent
      } else if (type === 'image') {
        state.progressImage = percent
      } else if (type === 'video') {
        state.progressVideo = percent
      } else {
        state.progress = percent
      }
    }).addCase(uploadFiles.fulfilled, (state, action) => {
      state.progress = 0
    }).addCase(uploadAudio.fulfilled, (state, action) => {
      state.progressAudio = 0
    }).addCase(uploadImages.fulfilled, (state, action) => {
      state.progressImage = 0
    }).addCase(uploadVideo.fulfilled, (state, action) => {
      state.progressVideo = 0
    })
  }
})


export default _.reducer
