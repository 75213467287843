// ** Initial State
const initialState = {
  listProvinsi: [],
  listDaerah: [],
  listCategory: [],
  listTopic: [],
  listRole: [],
  listTimeCategory: [],

  total: 1,
  params: {},
  alert: {},
  alertSave: {},
  loading: false
}

const master = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    case 'GET_LIST_PROVINCE':
      return {
        ...state,
        listProvinsi: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LIST_REGIONAL':
      return {
        ...state,
        listDaerah: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LIST_CATEGORY':
      return {
        ...state,
        listCategory: action.listCategory,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LIST_TOPIC':
      return {
        ...state,
        listTopic: action.listTopic,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_LIST_ROLE':
      return { ...state, alert: action.alert, listRole: action.data, alertSave: {}, loading: false}

    case 'GET_LIST_TIME_CATEGORY':
      return { ...state, alert: action.alert, listTimeCategory: action.data}

    default:
      return { ...state, alert: action.alert  }
  }
}
export default master
