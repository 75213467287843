// ** Redux Imports

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import ToastComponent from '@components/toast'
import axios from 'axios'
import { downloadCSV } from '@exports'
import moment from 'moment'
import { toast } from 'react-toastify'

export const progressEvent = createAsyncThunk(
  'gallery/progressEvent',
  (values) => {
    return values
  }
)
export const getData = createAsyncThunk('gallery/getData', async (params) => {
  const response = await axios.get('/api/private/gallery', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

// ** Get Detail
export const getDetailGallery = createAsyncThunk(
  'gallery/getDetailGallery',
  async (id) => {
    const response = await axios.get(`/api/private/gallery/${id}`)
    return response.data.data
  }
)

// ** created
export const createGallery = createAsyncThunk(
  'gallery/createGallery',
  (payload, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json'
        // 'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e
        const percent = Math.floor((loaded * 100) / total)
        // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
        if (percent <= 100) {
          dispatch(progressEvent({ type: '', percent }))
          // dispatch({
          //   type: 'UPLOAD_PROGRESS',
          //   data: percent
          // })
        }
      }
    }
    return axios
      .post(`/api/v2/private/gallery`, payload, config)
      .then((res) => {
        return res?.data
      })
      .catch((err) => {
        const { alert } = { ...err.response.data }
        toast.success(
          <ToastComponent
            code={alert?.code}
            message={alert?.message}
            color='danger'
          />,
          { autoClose: 5000, hideProgressBar: true, closeButton: true }
        )

        return err.response.data
      })
  }
)

export const updateGallery = createAsyncThunk(
  'gallery/updateGallery',
  ({ id, body }, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json' //,'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e
        const percent = Math.floor((loaded * 100) / total)
        console.log(
          'onUploadProgress',
          `${loaded}kb of ${total}kb | ${percent}%`
        )
        dispatch(progressEvent({ type: '', percent }))
        if (percent <= 100) {
          // dispatch({
          //   type: 'UPLOAD_PROGRESS',
          //   data: percent
          // })
        }
      }
    }
    return axios
      .put(`/api/v2/private/gallery/${id}`, body, config)
      .then((res) => {
        // toast.success(res?.data.alert.message)
        // dispatch(getData(getState().booking.params))
        return res?.data
      })
      .catch((err) => {
        return err
      })
  }
)

export const duplicateNews = createAsyncThunk(
  'gallery/duplicateNews',
  async (id, { dispatch, getState }) => {
    const response = await axios.post(`/api/private/gallery/duplicate/${id}`)
    await dispatch(getData(getState().gallery.params))
    return response.data
  }
)

export const destroyGallery = createAsyncThunk(
  'gallery/destroyGallery',
  async (id, { dispatch, getState }) => {
    const response = await axios.patch(`/api/private/gallery/${id}`)
    await dispatch(getData(getState().gallery.params))
    return response.data
  }
)
export const destroyFile = createAsyncThunk(
  'gallery/destroyFile',
  async (payload, { dispatch, getState }) => {
    const response = axios.patch(
      `/api/private/gallery/${payload.id}/file`,
      payload,
      config
    )
    await dispatch(getData(getState().gallery.params))
    return response.data
  }
)

export const unAssignMe = createAsyncThunk(
  'gallery/unAssignMe',
  async (id, { dispatch, getState }) => {
    const response = await axios.patch(`/api/private/gallery/${id}/un-assign`)
    // await dispatch(getDetailGallery(id))
    window.location = '/gallery/list'
    return response.data
  }
)

export const getReports = createAsyncThunk(
  'gallery/deleteTask',
  async (params) => {
    const response = await axios.get(`/api/private/gallery`, { params })
    const { start_date, end_date } = params
    downloadCSV(
      `Berita Foto-${moment(start_date).format('YMMDD')}-${moment(
        end_date
      ).format('YMMDD')}`,
      response.data.data.map((row) => {
        return {
          judul: row.judul,
          nama_kategori: row.nama_kategori,
          nama_daerah: row.nama_daerah,
          author_name: row.author_name,
          editor_name: row.editor_name,
          publish_date: moment(row.publishedAt).format('DD-MM-YYYY hh:mm'),
          url: `https://www.rri.co.id/berita-foto${
            row.id_daerah === '1' ? '' : `/${row.slug_daerah}`
          }/${row.id}/${row.slug}`
        }
      })
    )
    return response.data
  }
)

export const uploadImages = createAsyncThunk(
  'booking/uploadImages',
  async (payload, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e
        const percent = Math.floor((loaded * 100) / total)
        // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
        dispatch(progressEvent({ type: 'image', percent }))
        if (percent <= 100) {
          // dispatch({
          //   type: 'UPLOAD_PROGRESS',
          //   data: percent
          // })
        }
      }
    }

    const { id, prefix, bucket, files, notes } = payload

    const form = new FormData()
    if (prefix) form.append('prefix', prefix)
    if (bucket) form.append('bucket', bucket)
    if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
    form.append('notes', notes)
    // for (const file of files) {
    //   form.append("files", file)
    // }
    // for (key in form) {
    //   console.log(payload, key)
    // }
    const res = await axios.post(`/api/private/upload/image`, form, config)
    return res.data.data
  }
)

export const uploadAudio = createAsyncThunk(
  'booking/uploadAudio',
  async (payload, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e
        const percent = Math.floor((loaded * 100) / total)
        console.log(
          'onUploadProgress',
          `${loaded}kb of ${total}kb | ${percent}%`
        )
        dispatch(progressEvent({ type: 'audio', percent }))
        if (percent <= 100) {
          // dispatch({
          //   type: 'UPLOAD_PROGRESS',
          //   data: percent
          // })
        }
      }
    }

    const { id, prefix, bucket, files, notes } = payload

    const form = new FormData()
    if (prefix) form.append('prefix', prefix)
    if (bucket) form.append('bucket', bucket)
    if (files) form.append('files', files, `${files.lastModified}-${files.name}`)
    form.append('notes', notes)
    // for (const file of files) {
    //   form.append("files", file)
    // }
    // for (key in form) {
    //   console.log(payload, key)
    // }
    const res = await axios.post(`/api/private/upload/image`, form, config)
    return res.data.data
  }
)

export const _ = createSlice({
  name: 'gallery',
  initialState: {
    data: [],
    selected: {},
    progress: 0,
    progressAudio: 0,
    progressImage: 0,
    progressVideo: 0,
    params: {},
    total: 0
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selectedNews: (state, action) => {
  //     state.selectedNews = action.payload
  //   }
  // },
  extraReducers: (builder) => {
    builder
      .addCase(createGallery.fulfilled, (state, action) => {
        state.progress = 0
      })
      .addCase(updateGallery.fulfilled, (state, action) => {
        state.progress = 0
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.progress = 0
        state.progressAudio = 0
        state.progressImage = 0
        state.progressVideo = 0
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selected = {}
      })
      .addCase(getDetailGallery.fulfilled, (state, action) => {
        state.progress = 0
        state.progressAudio = 0
        state.progressImage = 0
        state.progressVideo = 0
        state.selected = action.payload
      })
      .addCase(progressEvent.fulfilled, (state, action) => {
        const { type, percent } = action.payload
        if (type === 'audio') {
          state.progressAudio = percent
        } else if (type === 'image') {
          state.progressImage = percent
        } else if (type === 'video') {
          state.progressVideo = percent
        } else {
          state.progress = percent
        }
      })
  }
})

// export const { reOrderTasks, selectedNews } = _.actions

export default _.reducer
