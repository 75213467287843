// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** To Exports Data
import { downloadCSV } from '@exports'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('module/getData', async params => {
  const response = await axios.get('/api/private/module', { params })
  return {
    params,
    data: response.data.data
    // totalPages: response.data.paging.totalItems
  }
})

export const getDetailNews = createAsyncThunk('module/getDetailNews', async id => {
  const response = await axios.get(`/api/private/berita/${id}`)
  return response.data.data
})

export const createNews2 = createAsyncThunk('module/createNews2', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  console.log('payload', payload)

  const response = await axios.post(`/api/private/berita2`, payload, config)
  // await dispatch(getData(getState().module.params))
  return response.data
})

export const updateNews = createAsyncThunk('module/updateNews', async (payload, { dispatch, getState }) => {
  const { id, body } = payload
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await axios.put(`/api/private/berita/${id}`, body, config)
  // await dispatch(getData(getState().module.params))
  return response.data
})

export const duplicateNews = createAsyncThunk('module/duplicateNews', async (id, { dispatch, getState }) => {
  const response = await axios.post(`/api/private/berita/duplicate/${id}`)
  await dispatch(getData(getState().module.params))
  return response.data
})

export const deleteNews = createAsyncThunk('module/deleteTask', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`/api/private/berita/${id}`)
  await dispatch(getData(getState().module.params))
  return response.data
})

export const getReports = createAsyncThunk('module/deleteTask', async params => {
  const response = await axios.get(`/api/private/berita`, { params })
  // await dispatch(getData(getState().module.params))
  downloadCSV('Berita Portal',
    response.data.data.map((row) => {
      return { 
        JUDUL_BERITA: row.judul, 
        NAMA_KATEGORI: row.nama_kategori,
        NAMA_DAERAH: row.nama_daerah,
        AUTHOR_NAME: row.author_name,
        EDITOR_NAME: row.editor_name,
        PUBLISH_DATE: row.publishedAt,
        URL: `https://www.rri.co.id/${row.id_daerah === '1' ? '' : `${row.slug_daerah}`}/${row.slug_kategori}/${row.id}/${row.slug}`
      }
    })
  )
  return response.data
})

export const moduleSlice = createSlice({
  name: 'module',
  initialState: {
    data: [],
    selectedTask: {},
    selectedNews: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selectTask: (state, action) => {
  //     state.selectedTask = action.payload
  //   }
  // },
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      // state.total = action.payload.totalPages
    })
    .addCase(getDetailNews.fulfilled, (state, action) => {
      state.selectedNews = action.payload
    })
  }
})

export const { reOrderTasks, selectTask } = moduleSlice.actions

export default moduleSlice.reducer
