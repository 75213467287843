// ** Initial State
const initialState = {
  allData: [],
  data: [],
  listRole: [],
  total: 1,
  params: {},
  selectedUser: null,
  userProfile: null,
  alert: {},
  dashboard: {},
  alertSave: {},
  loading: false
}

const users = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    case 'GET_ALL_DATA':
      return { ...state, allData: action.data, loading: false }
    case 'GET_DATA':
      return {
        ...state,
        alert: action.alert,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        alertSave: {},
        loading: false
      }
    case 'GET_USER':
      return { ...state, alert: action.alert, selectedUser: action.selectedUser, alertSave: {}, loading: false }
    case 'GET_USER_PROFILE':
      return { ...state, alert: action.alert, userProfile: action.data, alertSave: {}, loading: false }
    case 'ADD_USER':
    case 'getDashboard':
      return { ...state,
         alert: action.alert,
         dashboard: action.dashboard, 
         alertSave: {},
         loading: false }

    case 'GET_LIST_ROLE':
      return { ...state, alert: action.alert, listRole: action.data, alertSave: {}, loading: false}

    case 'SUCCESS':
      return { ...state, alertSave: action.alert, loading: false }
    case 'ERR':
      return { ...state, alertSave: action.alert, loading: false }
    default:
      return { ...state, loading: false}
  }
}
export default users
