// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import calendar from '@src/views/apps/calendar/store/reducer'
import chat from '@src/views/apps/chat/store/reducer'
import dataTables from '@src/views/tables/data-tables/store/reducer'
import iklan from '@store/actions/iklan'
import kegiatan from '@store/actions/kegiatan'
import modules from '@store/actions/modules'
import pengumuman from '@store/actions/pengumuman'
import penyiar from '@store/actions/penyiar'
import program from '@store/actions/program'
import reports from '@store/actions/reports'
import category from './category'
import dashboard from './dashboard'
import layout from './layout'
import master from './master'
// import menu from './menu'
import navbar from './navbar'
import newsLibrary from './news-library'
import podcast from './podcast'
import profileSatker from './profile-satker'
import regional from './regional'
import roles from './roles'
import tickets from './tickets'
import topic from './topic'
import users from './users'

// ** Reducers ImportsSettings
import auth from '@store/actions/auth'
import banner from '@store/actions/banner'
import editorial from '@store/actions/editorial'
import gallery from '@store/actions/gallery'
import indepth from '@store/actions/indepth'
import infografis from '@store/actions/infografis'
import menu from '@store/actions/menu'
import news from '@store/actions/news'
import settings from '@store/actions/settings'
import stream from '@store/actions/stream'
import uploads from '@store/actions/uploads'
import video from '@store/actions/video'

const rootReducer = combineReducers({
  auth,
  chat,
  newsLibrary,
  // users,
  navbar,
  layout,
  calendar,
  dataTables,
  tickets,
  dashboard,
  users,
  modules,
  news,
  category,
  regional,
  master,
  menu,

  kegiatan,
  podcast,
  profileSatker,
  penyiar,
  pengumuman,
  program,
  roles,
  stream,
  topic,
  editorial,
  infografis,
  indepth,
  iklan,
  reports,

  // ** rootReducer settings
  banner,
  gallery,
  uploads,
  video,
  settings
})

export default rootReducer
