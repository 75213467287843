// ** Initial State
const initialState = {
  params: {},
  alert: {},
  dashboard: {},
  dashboardDaily: {},
  // statistic: [],
  dashboard_regional: [],
  loading: false,
  statisticDaily: [],
  statistic: {
    alert: {},
    params: {},
    data: [],
    total: 0
  }
}

const dashboard = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    case 'Dashboard':
      return { ...state,
         alert: action.alert,
         dashboard: action.data,
         loading: false 
      }

    case 'DashboardDaily':
      return { ...state,
         alert: action.alert,
         dashboardDaily: action.data,
         loading: false 
      }

    case 'statistic':
      return { ...state,
         loading: false,
         statistic: {
          alert: action.alert,
          params: action.params,
          data: action.data,
          total: action.totalPages
        }
      }
    case 'statisticDaily':
      return { ...state,
        alert: action.alert,
        statisticDaily: action.data,
        loading: false,
        total: 0
      }

    case 'dashboard_regional':
      return { ...state,
         alert: action.alert,
         dashboard_regional: action.data,
         loading: false 
      }

  case 'analyticsHour':
      return { ...state,
         alert: action.alert,
         analyticsHour: action.data,
         loading: false 
      }

    case 'SUCCESS':
      return { ...state, alertSave: action.alert, loading: false }
    case 'ERR':
      return { ...state, alertSave: action.alert, loading: false }
    default:
      return { ...state, loading: false}
  }
}
export default dashboard
