// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

// ** Function Baner
export const getData = createAsyncThunk('banner/getData', async params => {
  const response = await axios.get('/api/private/banner', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

export const getDetailBanner = createAsyncThunk('banner/getDetailBanner', async id => {
  const response = await axios.get(`/api/private/banner/${id}`)
  return response.data.data
})

export const createBanner2 = createAsyncThunk('banner/createBanner2', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await axios.post(`/api/private/banner`, payload, config)
  // await dispatch(getData(getState().banner.params))
  console.log(response)

  return response.data
})
export const createBanner = createAsyncThunk('banner/createBanner', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json'
      // 'Content-Type': 'multipart/form-data'
    }
  }
  return await axios
    .post(`/api/private/banner`, payload, config)
    .then((res) => {
      // toast.success(res?.data.alert.message)
      // dispatch(getData(getState().booking.params))
      return res.data
    })
    .catch((err) => {
      return err
    })
})


export const updateBanner = createAsyncThunk('banner/updateBanner', async (payload, { dispatch, getState }) => {
  const { id, body } = payload
  const config = {
    headers: {
      Accept: 'application/json'
      // 'Content-Type': 'multipart/form-data'
    },
    onUploadProgress: (progressEvent) => {
      const { loaded, total } = progressEvent
      const percent = Math.floor((loaded * 100) / total)
      console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
      if (percent <= 100) {
        // dispatch({
        //   type: 'UPLOAD_PROGRESS',
        //   data: percent
        // })
      }
    }
  }
  const response = await axios.put(`/api/private/banner/${id}`, body, config)
  await dispatch(getData(getState().banner.params))
  return response.data
})

export const destroyBanner = createAsyncThunk('banner/destroyBanner', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`/api/private/banner/${id}`)
  await dispatch(getData(getState().banner.params))
  return response.data
})

export const _ = createSlice({
  name: 'banner',
  initialState: {
    data: [],
    selected: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selected: (state, action) => {
  //     state.selected = action.payload
  //   }
  // },
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
      state.selected = {}
    })
    .addCase(getDetailBanner.fulfilled, (state, action) => {
      state.selected = action.payload || {}
    })
  }
})
export default _.reducer
