// ** Initial State
const initialState = {
  data: [],
  params: {},
  currentMail: null,
  total: 1,
  emailsMeta: {
    draft: 4,
    inbox: 3,
    spam: 2
  },
  selected: [],
  alertSave:{},
  loading: false
}

const EmailReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_MAILS':
      // ** If currentMail is not null / undefined then find and set currentMail
      let currMail = null
      if (state.currentMail !== null && state.currentMail !== undefined) {
        currMail = action.data.find(i => i.id === state.currentMail.id)
      }
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        // emailsMeta: action.data.emailsMeta,
        params: action.params,
        currentMail: currMail,
        alertSave: {}, 
        loading: false
      }
    case 'PAGINATE_MAIL':
      // ** Find current mail & add hasNextMail & hasPreviousMail props to current mail object based on index
      const data = action.data
      const dataIndex = state.data.findIndex(i => i.id === data.id)
      dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
      dataIndex === state.data.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
      return { ...state, currentMail: data }
    case 'UPDATE_LIBRARY':
      // // ** Find and updated mail form data to json
      // function updateMailData(email) {
      //   Object.assign(email, action.payload)
      // }
      // state.data.forEach(email => {
      //   if (action.id.includes(email.id)) updateMailData(email)
      // })

      return { ...state, alertSave: action.alert, loading: false }
    case 'UPDATE_MAIL_LABEL':
      return { ...state }
    case 'SELECT_CURRENT_LIBRARY':
      return { ...state, currentMail: action.mail }
    case 'SELECT_MAIL':
      // ** Select data
      const selected = state.selected
      if (!selected.includes(action.id)) {
        selected.push(action.id)
      } else {
        selected.splice(selected.indexOf(action.id), 1)
      }
      return { ...state, selected, alertSave: {}, loading: false }
    case 'SELECT_ALL_MAIL':
      // ** Select all data
      const selectAllMails = []
      if (action.val) {
        selectAllMails.length = 0
        state.data.forEach(mail => selectAllMails.push(mail.id))
      } else {
        selectAllMails.length = 0
      }
      return { ...state, selected: selectAllMails, alertSave: {}, loading: false }
    case 'RESET_SELECT_MAILS':
      // ** Reset(remove) all selected data
      return { ...state, selected: [], currentMail: null, alertSave: {}, loading: false}
    case 'SAVE_LIBRARY':
      return { ...state, alertSave: action.alert, loading: false }
    default:
      return state
  }
}

export default EmailReducer
