// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'

// ** Function Baner
export const getData = createAsyncThunk('settings/getData', async (params) => {
  const response = await axios.get('/api/app-settings', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

export const appSettingPreview = createAsyncThunk(
  'settings/appSettingPreview',
  async (id) => {
    const response = await axios.get(`/api/app-settings/${id || 0}`)
    return response.data.data
  }
)

export const updateAppSettingPreview = createAsyncThunk(
  'settings/updateAppSettingPreview',
  async (payload, { dispatch, getState }) => {
    const { id, body } = payload
    const config = {
      headers: {
        Accept: 'application/json'
      },
      onUploadProgress: (progressEvent) => {
        const { loaded, total } = progressEvent
        const percent = Math.floor((loaded * 100) / total)
        console.log(
          'onUploadProgress',
          `${loaded}kb of ${total}kb | ${percent}%`
        )
        if (percent <= 100) {
          // dispatch({
          //   type: 'UPLOAD_PROGRESS',
          //   data: percent
          // })
        }
      }
    }
    const response = await axios.put(
      `/api/app-settings/${id || 0}`,
      body,
      config
    )
    // await dispatch(getData(getState().settings.params))
    return response.data
  }
)

export const destroyBanner = createAsyncThunk(
  'settings/destroyBanner',
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/api/app-settings/${id}`)
    await dispatch(getData(getState().settings.params))
    return response.data
  }
)

export const _ = createSlice({
  name: 'settings',
  initialState: {
    data: [],
    selected: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selected: (state, action) => {
  //     state.selected = action.payload
  //   }
  // },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selected = {}
      })
      .addCase(appSettingPreview.fulfilled, (state, action) => {
        state.selected = action.payload || {}
      })
  }
})
export default _.reducer
