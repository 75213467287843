// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** To Exports Data
import { downloadCSV } from '@exports'

// ** Axios Imports
import axios from 'axios'

export const addProgram = createAsyncThunk('program/addProgram', async (payload, { dispatch, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  console.log('payload', Object.fromEntries(payload))
  const response = await axios.post(`/api/program-unggulan`, payload, config)
  // await dispatch(getData(getState().program.params))
  return response.data
})

export const getData = createAsyncThunk('program/getData', async params => {
  const response = await axios.get('/api/program-unggulan', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

export const getDetailProgram = createAsyncThunk('program/getDetailNews', async id => {
  const response = await axios.get(`/api/program-unggulan/${id}`)
  return response.data.data
})


export const updateProgram = createAsyncThunk('program/updateProgram', async (payload, { dispatch, getState }) => {
  const { id, body } = payload
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  console.log('payload', Object.fromEntries(body))
  const response = await axios.put(`/api/program-unggulan/${id}`, body, config)
  // await dispatch(getData(getState().program.params))
  return response.data
})

export const deleteProgram = createAsyncThunk('program/deleteProgram', async (id, { dispatch, getState }) => {
  const response = await axios.delete(`/api/program-unggulan/${id}`)
  await dispatch(getData(getState().program.params))
  return response.data
})

export const getReports = createAsyncThunk('program/deleteProgram', async params => {
  const response = await axios.get(`/api/program-unggulan`, { params })
  // await dispatch(getData(getState().program.params))
  downloadCSV('Berita Portal',
    response.data.data.map((row) => {
      return { 
        JUDUL_BERITA: row.judul, 
        NAMA_KATEGORI: row.nama_kategori,
        NAMA_DAERAH: row.nama_daerah,
        AUTHOR_NAME: row.author_name,
        EDITOR_NAME: row.editor_name,
        PUBLISH_DATE: row.publishedAt,
        URL: `https://www.rri.co.id/${row.id_daerah === '1' ? '' : `${row.slug_daerah}`}/${row.slug_kategori}/${row.id}/${row.slug}`
      }
    })
  )
  return response.data
})

export const programSlice = createSlice({
  name: 'program',
  initialState: {
    data: [],
    selectedTask: {},
    selected: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  reducers: {
    // reOrderTasks: (state, action) => {
    //   state.data = action.payload
    // },
    selected: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
    .addCase(getDetailProgram.fulfilled, (state, action) => {
      state.selected = action.payload
    })
  }
})

export const { reOrderTasks, selectTask, selected } = programSlice.actions

export default programSlice.reducer
