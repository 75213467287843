import { downloadCSV } from '@exports'
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import moment from 'moment'

export const getDataBerita = createAsyncThunk('reports/getDataBerita', async params => {
    const response = await axios.get('/api/private/reports/all', { params })

    return {
        params,
        data: response.data.data
    }
})

const getRoleName = id => {
    if (id === 22) return 'Penulis/ Reporter'
    else if (id === 26) return 'Penyiar'
    else if (id === 26) return 'Pegawai Non-Operasional'
    else if (id === 23) return 'Editor/ Pengelola'
    else return '-'
}

export const getReportBerita = createAsyncThunk('reports/getReportBerita', async params => {
    const response = await axios.get('/api/private/reports/all', { params })
    const { from, to } = params

    downloadCSV(`ExportBerita-${moment(from).format('YMMDD')}-${moment(to).format('YMMDD')}`,
        response.data.data.map((row) => {
            const roleName = ''
           
            return {
                'JUDUL BERITA': row.judul,
                'NAMA KATEGORI': row.nama_kategori,
                'NAMA DAERAH': row.nama_satker,
                'AUTHOR NAME': row.penulis,
                'ROLE NAME': getRoleName(row.role_id),
                'EDITOR NAME': row.nama_editor,
                'PUBLISH DATE': row.publish_date,
                HITS: row.hits,
                'URL BERITA': row.url
            }
        })
    )

    return response.data
})

export const getDataRekapitulasi = createAsyncThunk('reports/getDataRekapitulasi', async params => {
    const response = await axios.get('/api/private/reports/rekapitulasi', { params })

    return {
        paramsRekapitulasi: params,
        dataRekapitulasi: response.data.data
    }
})

export const getReportRekapitulasi = createAsyncThunk('reports/getReportRekapitulasi', async params => {
    const response = await axios.get('/api/private/reports/rekapitulasi', { params })
    const { from, to } = params

    downloadCSV(`ExportRekap-${moment(from).format('YMMDD')}-${moment(to).format('YMMDD')}`,
        response.data.data.map((row) => {
            const exportData = {}

            row.detail.map((r) => {
                exportData[r.tanggal] = r.publish
            })

            exportData.NAMA_DAERAH = row.nama_daerah
            
            return exportData
        })
    )

    return response.data
})

export const reportsSlice = createSlice({
    name: 'reports',
    initialState: {
        data: [],
        dataRekapitulasi: [],
        paramsRekapitulasi: '',
        berita:[],
        rekap: []

    },
    extraReducers: builder => {
        builder
        .addCase(getDataBerita.fulfilled, (state, action) => {
            state.data = action.payload.data
            state.params = action.payload.params
        })
        .addCase(getDataRekapitulasi.fulfilled, (state, action) => {
            state.dataRekapitulasi = action.payload.dataRekapitulasi
            state.paramsRekapitulasi = action.payload.paramsRekapitulasi
        })
        .addCase(getReportBerita.fulfilled, (state, action) => {
            state.berita = action.payload.data
            state.params = action.payload.params
        })
    }
})

export default reportsSlice.reducer