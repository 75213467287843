
// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('pengumuman/getData', async params => {
  const response = await axios.get('/api/private/pengumuman', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

export const createPengumuman = createAsyncThunk('pengumuman/createPengumuman', async(payload, { dispacth, getState }) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data'
    }
  }
  const response = await axios.post(`/api/private/pengumuman`, payload, config)

  return response.data
})


export const getChartPemilu = createAsyncThunk('pengumuman/getChartPemilu', async params => {
  const response = await axios.get('/public/pemilu2024/v1/parsing-data/chart', { params })
  return {
    params,
    data: response.data.data
  }
})

export const getMaintenance = createAsyncThunk('pengumuman/getMaintenance', async params => {
  const response = await axios.get('/api/private/settings/maintenance', { params })
  return response.data.data
})

export const pengumumanSlice = createSlice({
  name: 'pengumuman',
  initialState: {
    data: [],
    selected: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  reducers: {
    // reOrderTasks: (state, action) => {
    //   state.data = action.payload
    // },
    selected: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: builder => {
    builder
    .addCase(getData.fulfilled, (state, action) => {
      state.data = action.payload.data
      state.params = action.payload.params
      state.total = action.payload.totalPages
    })
  }
})

export const { selected } = pengumumanSlice.actions

export default pengumumanSlice.reducer
