// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  currentMail: null,
  emailsMeta: {
    draft: 4,
    inbox: 3,
    spam: 2
  },
  selected: {},
  alert:{},
  loading: false
}

const ProfileSatkerReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_DATA':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params,
        alert:{},
        selected:{},
        loading: false
      }
    case 'GET_DETAIL':
      return { 
        ...state, 
        selected: action.selected,
        alert: {},
        loading: false
      }
    case 'SUCCESS':
      return { ...state, alert: action.alert, loading: false }


    case 'PAGINATE_MAIL':
      // ** Find current mail & add hasNextMail & hasPreviousMail props to current mail object based on index
      const data = action.data
      const dataIndex = state.mails.findIndex(i => i.id === data.id)
      dataIndex === 0 ? (data.hasPreviousMail = false) : (data.hasPreviousMail = true)
      dataIndex === state.mails.length - 1 ? (data.hasNextMail = false) : (data.hasNextMail = true)
      return { ...state, currentMail: data }
    case 'UPDATE_LIBRARY':
      // // ** Find and updated mail form data to json
      // function updateMailData(email) {
      //   Object.assign(email, action.payload)
      // }
      // state.mails.forEach(email => {
      //   if (action.id.includes(email.id)) updateMailData(email)
      // })

      return { ...state, alert: action.alert, loading: false }
    case 'UPDATE_MAIL_LABEL':
      return { ...state }
    case 'SELECT_CURRENT_LIBRARY':
      return { ...state, currentMail: action.mail }
    case 'SELECT_MAIL':
      // ** Select mails
      const selected = state.selected
      if (!selected.includes(action.id)) {
        selected.push(action.id)
      } else {
        selected.splice(selected.indexOf(action.id), 1)
      }
      return { ...state, selected, alert: {}, loading: false }
    case 'SELECT_ALL_MAIL':
      // ** Select all mails
      const selectAllMails = []
      if (action.val) {
        selectAllMails.length = 0
        state.mails.forEach(mail => selectAllMails.push(mail.id))
      } else {
        selectAllMails.length = 0
      }
      return { ...state, selected: selectAllMails, alert: {}, loading: false }
    case 'RESET_SELECT_MAILS':
      // ** Reset(remove) all selected mails
      return { ...state, selected: [], currentMail: null, alert: {}, loading: false}
    case 'SAVE_LIBRARY':
      return { ...state, alert: action.alert, loading: false }
    default:
      return state
  }
}

export default ProfileSatkerReducer
