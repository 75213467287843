// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selected: {},
  alert: {},
  listDaerah: [],
  alertSave: {},
  loading: false
}

const regional = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    case 'GET_REGIONAL':
      return {
        ...state,
        listDaerah: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DETAIL':
      return { 
        ...state, 
        selected: action.selected 
      }
    default:
      return { 
        ...state, 
        alert: action.alert  
      }
  }
}
export default regional
