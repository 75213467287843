// ** Initial State
const initialState = {
  listTopic: [],
  data: [],
  total: 1,
  params: {},
  selectedTopic: null,
  alert: {},
  alertSave: {},
  loading: false
}

const topic = (state = initialState, action) => {
  switch (action.type) {
    case 'LOADING':
      return { ...state, alertSave: {}, loading: true }
    
    case 'GET_ALL':
      return {
        ...state,
        listTopic: action.data,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DETAIL':
      return { 
        ...state, 
        selectedTopic: action.selected 
      }
    case 'POST':
      return { 
        ...state, 
        alert: action.alert 
      }
    case 'SUCCESS':
      return { ...state, alertSave: action.alert }
      
    case 'ERR':
      return { ...state, alertSave: action.alert }

    default:
      return { ...state}
  }
}
export default topic
