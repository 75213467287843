// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** To Exports Data
import { downloadCSV } from '@exports'

// ** Axios Imports
import axios from 'axios'

export const addKegiatan = createAsyncThunk(
  'kegiatan/addKegiatan',
  async (payload, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }
    console.log('payload', Object.fromEntries(payload))
    const response = await axios.post(`/api/kegiatan`, payload, config)
    // await dispatch(getData(getState().kegiatan.params))
    return response.data
  }
)

export const getData = createAsyncThunk('kegiatan/getData', async (params) => {
  const response = await axios.get('/api/kegiatan', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

export const getDetail = createAsyncThunk(
  'kegiatan/getDetailNews',
  async (id) => {
    const response = await axios.get(`/api/kegiatan/${id}`)
    return response.data.data
  }
)

export const updateKegiatan = createAsyncThunk(
  'kegiatan/updateKegiatan',
  async (payload, { dispatch, getState }) => {
    const { id, body } = payload
    const config = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'multipart/form-data'
      }
    }
    console.log('payload', Object.fromEntries(body))
    const response = await axios.put(`/api/kegiatan/${id}`, body, config)
    // await dispatch(getData(getState().kegiatan.params))
    return response.data
  }
)

export const deleteKegiatan = createAsyncThunk(
  'kegiatan/deleteKegiatan',
  async (id, { dispatch, getState }) => {
    const response = await axios.delete(`/api/kegiatan/${id}`)
    await dispatch(getData(getState().kegiatan.params))
    return response.data
  }
)

export const getReports = createAsyncThunk(
  'kegiatan/deleteKegiatan',
  async (params) => {
    const response = await axios.get(`/api/kegiatan`, { params })
    // await dispatch(getData(getState().kegiatan.params))
    downloadCSV(
      'Berita Portal',
      response.data.data.map((row) => {
        return {
          JUDUL_BERITA: row.judul,
          NAMA_KATEGORI: row.nama_kategori,
          NAMA_DAERAH: row.nama_daerah,
          AUTHOR_NAME: row.author_name,
          EDITOR_NAME: row.editor_name,
          PUBLISH_DATE: row.publishedAt,
          URL: `https://www.rri.co.id/${
            row.id_daerah === '1' ? '' : `${row.slug_daerah}`
          }/${row.slug_kategori}/${row.id}/${row.slug}`
        }
      })
    )
    return response.data
  }
)

export const penyiarSlice = createSlice({
  name: 'kegiatan',
  initialState: {
    data: [],
    selectedTask: {},
    selected: {}
    // params: {
    //   filter: '',
    //   search: '',
    //   sort: '',
    //   tag: ''
    // }
  },
  reducers: {
    // reOrderTasks: (state, action) => {
    //   state.data = action.payload
    // },
    selected: (state, action) => {
      state.selected = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selected = {}
      })
      .addCase(getDetail.fulfilled, (state, action) => {
        state.selected = action.payload
      })
  }
})

export const { reOrderTasks, selectTask, selected } = penyiarSlice.actions

export default penyiarSlice.reducer
