// ** Initial State
const initialState = {
  data: [],
  total: 1,
  params: {},
  selectedCategory: {},
  alert: {},
  listDaerah: [],
  listCategory: [],
  alertSave: {},
  loading: false
}

const category = (state = initialState, action) => {
  switch (action.type) {
    case 'GET_ALL':
      return {
        ...state,
        data: action.data,
        total: action.totalPages,
        params: action.params
      }
    case 'GET_DETAIL':
      return { 
        ...state, 
        selectedCategory: action.selected 
      }
    case 'SELECT_CATEGORY':
      return { ...state, selectedCategory: action.category }

    case 'UPDATE_CATEGORY':
      return { ...state, alert: action.alert }
    
      case 'LOADING':
        return { ...state, alertSave: {}, loading: true }
        
      case 'SUCCESS':
        return { ...state, alertSave: action.alert }
        
      case 'ERR':
        return { ...state, alertSave: action.alert }
  
      default:
        return { ...state}
  }
}
export default category
