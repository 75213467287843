// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

// ** To Exports Data

// ** Axios Imports
import axios from 'axios'
// Toast
import ToastComponent from '@components/toast'
import { toast } from 'react-toastify'

// ** get all
export const progressEvent = createAsyncThunk(
  'menu/progressEvent',
  (values) => {
    return values
  }
)
export const getData = createAsyncThunk('menu/getData', async (params) => {
  const response = await axios.get('/api/private/menu', { params })
  return {
    params,
    data: response.data.data,
    totalPages: response.data.paging.totalItems
  }
})

// ** get detail
export const getDetailMenu = createAsyncThunk(
  'menu/getDetailMenu',
  async (id) => {
    const response = await axios.get(`/api/private/menu/${id}`)
    return response.data.data
  }
)

// ** created
export const createMenu = createAsyncThunk(
  'menu/createMenu',
  (payload, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json'
        // 'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e
        const percent = Math.floor((loaded * 100) / total)
        // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
        if (percent <= 100) {
          dispatch(progressEvent({ type: '', percent }))
        }
      }
    }
    return axios
      .post(`/api/private/menu`, payload, config)
      .then((res) => {
        dispatch(getData(getState().menu.params))
        return res?.data
      })
      .catch((err) => {
        const { alert } = { ...err.response.data }
        toast.success(
          <ToastComponent
            code={alert?.code}
            message={alert?.message}
            color='danger'
          />,
          { autoClose: 5000, hideProgressBar: true, closeButton: true }
        )

        return err.response.data
      })
  }
)

// ** updated
export const updateMenu = createAsyncThunk(
  'menu/updateMenu',
  ({ id, body }, { dispatch, getState }) => {
    const config = {
      headers: {
        Accept: 'application/json' //,'Content-Type': 'multipart/form-data'
      },
      onUploadProgress: (e) => {
        const { loaded, total } = e
        const percent = Math.floor((loaded * 100) / total)
        // console.log('onUploadProgress', `${loaded}kb of ${total}kb | ${percent}%`)
        dispatch(progressEvent({ type: '', percent }))
        if (percent <= 100) {
        }
      }
    }

    console.log(id, body)
    return axios
      .put(`/api/private/menu/${id}`, body, config)
      .then((res) => {
        // toast.success(res?.data.alert.message)
        dispatch(getData(getState().menu.params))
        return res?.data
      })
      .catch((err) => {
        return err
      })
  }
)

// ** destroy
export const destroyMenu = createAsyncThunk(
  'menu/destroyMenu',
  async (id, { dispatch, getState }) => {
    const response = await axios.patch(`/api/private/menu/${id}`)
    await dispatch(getData(getState().menu.params))
    return response.data
  }
)

export const _ = createSlice({
  name: 'menu',
  initialState: {
    data: [],
    selected: {},
    progress: 0,
    params: {},
    total: 0
  },
  // reducers: {
  //   reOrderTasks: (state, action) => {
  //     state.data = action.payload
  //   },
  //   selectedNews: (state, action) => {
  //     state.selectedNews = action.payload
  //   }
  // },
  extraReducers: (builder) => {
    builder
      .addCase(createMenu.fulfilled, (state, action) => {
        state.progress = 0
      })
      .addCase(updateMenu.fulfilled, (state, action) => {
        state.progress = 0
      })
      .addCase(getData.fulfilled, (state, action) => {
        state.progress = 0
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
        state.selected = {}
      })
      .addCase(getDetailMenu.fulfilled, (state, action) => {
        state.progress = 0
        state.selected = action.payload
      })
      .addCase(progressEvent.fulfilled, (state, action) => {
        const { type, percent } = action.payload
        if (type === 'audio') {
          state.progressAudio = percent
        } else if (type === 'image') {
          state.progressImage = percent
        } else if (type === 'video') {
          state.progressVideo = percent
        } else {
          state.progress = percent
        }
      })
  }
})

// export const { reOrderTasks, selectedNews } = _.actions

export default _.reducer
