// ** Third Party Components
import { Fragment } from 'react'
import Proptypes from 'prop-types'
import { Spinner } from 'reactstrap'
import { Check, Info, AlertTriangle } from 'react-feather'

import { Link, Route, Redirect } from 'react-router-dom'
// ** Custom Components
import Avatar from '@components/avatar'


const ToastComponent = props => {
  const {code, title, message, icon, color} = props
  if (code === 200 || code === 201) {
    return (
      <Fragment>
        <div className='toastify-header pb-0'>
          <div className='title-wrapper'>
            <Avatar size='sm' color={color} icon={<Check />} />
            <h6 className='toast-title'>{title}</h6>
          </div>
        </div>
        <div className='toastify-body'>
          <span>{message}</span>
        </div>
      </Fragment>
  
      // <Tag className={classnames('ui-loader', { [className]: className, show: blocking })}>
      //   {children}
      //   {blocking ? (
      //     <Fragment>
      //       <div
      //         className='overlay' /*eslint-disable */
      //         {...(blocking && overlayColor ? { style: { backgroundColor: overlayColor } } : {})}
      //         /*eslint-enable */
      //       ></div>
      //       <div className='loader'>{loader}</div>
      //     </Fragment>
      //   ) : null}
      // </Tag>
    )
  }

  return (
    <Fragment>
      <div className='toastify-header'>
        <div className='title-wrapper'>
          <Avatar size='sm' color='warning' icon={<AlertTriangle size={12} />} />
          <h6 className='text-warning ml-50 mb-0'>{title}</h6>
        </div>
        {/* <small className='text-muted'>11 Min Ago</small> */}
      </div>
      <div className='toastify-body'>
        <span>{message}</span>
      </div>
    </Fragment>
  )
}

export default ToastComponent

ToastComponent.defaultProps = {
  title: 'Notify',
  message: ''
  // blocking: false,
  // loader: <Spinner color='primary' />
}

ToastComponent.propTypes = {
  code: Proptypes.number,
  title: Proptypes.string,
  message: Proptypes.string,
  icon: Proptypes.any
}
